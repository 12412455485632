type Props = {
  width?: number | string;
  height?: number | string;
} & React.SVGProps<SVGSVGElement>;

export const SkeletonLoader = ({ width = 100, height = 28 }: Props) => (
  <svg
    className="skeleton-block"
    aria-labelledby="alapna6-aria"
    role="img"
    height={height}
    width={width}
  >
    <rect
      role="presentation"
      x="0"
      y="0"
      width="100%"
      height="100%"
      clipPath="url(#alapna6-diff)"
      style={{ fill: `url(#alapna6-animated-diff)` }}
    ></rect>
  </svg>
);

export const SkeletonLoaderDef = () => (
  <svg style={{ position: 'absolute', width: 0, height: 0 }}>
    <defs>
      <clipPath id="alapna6-diff">
        <rect x="0" y="0" rx="0" ry="0" width="100vw" height="100vh"></rect>
      </clipPath>
      <linearGradient id="alapna6-animated-diff" gradientTransform="translate(-2 0)">
        <stop offset="0%" stopColor="var(--color-neutral-50)" stopOpacity="1"></stop>
        <stop offset="50%" stopColor="#eee" stopOpacity="1"></stop>
        <stop offset="100%" stopColor="var(--color-neutral-50)" stopOpacity="1"></stop>
        <animateTransform
          attributeName="gradientTransform"
          type="translate"
          values="-2 0; 0 0; 2 0"
          dur="1s"
          repeatCount="indefinite"
        ></animateTransform>
      </linearGradient>
    </defs>
  </svg>
);
