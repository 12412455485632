import { Locale } from '../../Entities/Locale/Locale.entity';

/**
 * IANA time zone name
 */
type TimeZone = string;

export const LocaleTimezone: Record<Locale, TimeZone> = {
  uk: 'Europe/London',
  de: 'Europe/Berlin',
  fr: 'Europe/Paris',
};
