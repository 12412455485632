'use client';

import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { sessionStorageHelper } from '@core/Utils/SessionStorageHelper';

import { Calculation } from '../Entities/Calculation.entity';

export type CalculationAtom = Calculation | undefined | null;

export const calculationAtom = atom<CalculationAtom>(undefined);

export const calculationWithStorageAtom = atomWithStorage<CalculationAtom>(
  'calculation',
  undefined,
  sessionStorageHelper as any,
);

export const resetCalculationAtom = atom(null, (_get, set) => {
  set(calculationAtom, undefined);
  set(calculationWithStorageAtom, undefined);
});
